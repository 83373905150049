/*
 * The execution of this "legacy-base" bundle is render-blocking. It contains initialization code
 * that inline scripts on the pages rely upon. We don't want that and we eventually want to get rid
 * of this bundle (and the inline scripts).
 *
 * In general, we want to know the dependencies of our pages, so their dependencies should be defined
 * explicitly in the respective page's bundle. If you *really* need to have things globally on every
 * Cadenza page, consider adding them to the "base" bundle: The execution of that bundle is deferred,
 * i.e. it's not render-blocking.
 */

/* eslint-disable import/order -- Public path and polyfills need to be setup first */
import 'cadenza/public-path';
import 'cadenza/polyfills';

import $ from 'jquery';
import 'jquery-migrate/dist/jquery-migrate.min'; // jquery-migrate in production mode; import before any jQuery code
import { showHelpDialogIfNotLocallyDisabled } from 'cadenza/navigation/help-dialog/help-dialog';
import 'cadenza/header/subnav';
import 'cadenza/header/topnav';
import { logger } from 'cadenza/utils/logging';
import { commonI18n as i18n } from 'cadenza/utils/i18n/i18n';
import { applyThemeProperties } from 'cadenza/theming-api';
import { errorAlert } from 'cadenza/alert';
import { cadenzaUrl } from 'cadenza/utils/cadenza-url/cadenza-url';
import { postMessage } from 'cadenza/integration/post-message';
import { on } from 'cadenza/utils/event-util';
import {
  clearPerformanceMetadata,
  setPerformanceMetadata
} from 'cadenza/api-client/performance-analysis-api';
import { cadenzaTopWindow } from 'cadenza/utils/iframe';
import {
  initializeCadenzaStorage
} from 'cadenza/utils/storage/storage-util';

const Disy = window.Disy;

initializeCadenzaStorage();

applyThemeProperties(Disy.theme);

window.disyCommons = {
  i18n,
  logger,
  initializeNavigation: () => {
    // If the start page is loaded, we need to initialize navigation.
    if (isStartPage() || isProjectStartPage()) {
      importAndInitializeNavigation();
      return;
    }

    // Otherwise we initialize navigation on the first click of the navigationTrigger button.
    // After the initialization we need to programmatically click on the button one more time
    // because only then will the click event responsible for showing navigation be triggered.
    const navigationTrigger = document.getElementById('navigationTrigger');
    if (navigationTrigger) {
      on(
        navigationTrigger,
        'click',
        () => importAndInitializeNavigation(true),
        { once: true });
    }
  },
  cadenzaUrl,
  showHelpDialogIfNotLocallyDisabled,
  postMessage
};

function isStartPage () {
  const page = document.querySelector('.d-page');
  return page?.matches('.welcome-page');
}

function isProjectStartPage () {
  return document.querySelector('.d-page')?.matches('.project-start-page');
}

// We return a promise which is finished once the navigation is initialized.
async function importAndInitializeNavigation (toggleState) {
  // check if navigation is already initialized to prevent multiple instances of it
  // aria-describedby is set by initializeNavigation function
  if (!$('#navigationTrigger').attr('aria-describedby')) {
    const { initializeNavigation } = await import(/* webpackChunkName: "navigation" */ 'cadenza/navigation/navigation');
    await initializeNavigation(!!toggleState);
  }
}

if (Disy.debug) {
  logger.enableAll(false); // do not persist the log level in localStorage
}

Object.assign(Disy, {
  errorAlert,
  cadenzaTopWindow,

  // Used in internal load tests
  setPerformanceMetadata,
  clearPerformanceMetadata
});

// Make jQuery globally available for inline scripts (yes, they're bad)
window.$ = $;
window.jQuery = $;
