// Getter and Setter implemented according to:
// https://html.spec.whatwg.org/multipage/custom-elements.html#accessibility-semantics
//
// Note: It is more convenient not to use our `ariaBoolean` function,
// if we want to have same behavior as defined in the spec and ARIAMixin.

// eslint-disable-next-line no-prototype-builtins
if (!Element.prototype.hasOwnProperty('ariaDisabled')) {
  Object.defineProperty(Element.prototype, 'ariaDisabled', {
    enumerable: true,
    get (): string | null {
      return this.getAttribute('aria-disabled');
    },
    set (disabled: string | null) {
      if (disabled === 'true') {
        this.setAttribute('aria-disabled', 'true');
      } else {
        this.removeAttribute('aria-disabled');
      }
    }
  });
}

// eslint-disable-next-line no-prototype-builtins
if (!Element.prototype.hasOwnProperty('ariaRequired')) {
  Object.defineProperty(Element.prototype, 'ariaRequired', {
    enumerable: true,
    get (): string | null {
      return this.getAttribute('aria-required');
    },
    set (required: string | null) {
      if (required === 'true') {
        this.setAttribute('aria-required', 'true');
      } else {
        this.removeAttribute('aria-required');
      }
    }
  });
}
